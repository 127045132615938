import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {CompanyModel} from '@shared/models/company.model';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

@Injectable()
export class CompanyService extends ApiBaseService {
  public managementClients: CompanyModel[] = [];

  route_prefix = 'companies';

  constructor(
    public http: HttpClient,
    private globalSettingServices: GlobalSettingService,
  ) {
    super(http);
    this.globalSettingServices.useCompanyId$.subscribe((id) => {
      if (id) {
        this.getClients();
      }
    });
  }

  getClients() {
    this.http.post('company/get-clients', {}).subscribe((data: any) => {
      this.managementClients = data;
    });
  }

  self() {
    return this.http.get('companies/self');
  }

  users(companyId) {
    return this.http.get(`companies/${companyId}/users`);
  }
}
