import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {dashboardConfigs} from '@shared/config';
import {Conditions} from '@shared/models/conditions.model';

@Injectable()
export class ServiceService extends ApiBaseService {
  route_prefix = 'services';

  getVisits(service_id) {
    return this.http.post('service/visits', {service_id});
  }

  complete(service_id) {
    return this.http.post(`services/${service_id}/complete`, null);
  }

  engineers(serviceId) {
    return this.http.get(`service/get-engineers?service_id=${serviceId}`);
  }

  cancel(serviceId) {
    return this.http.post(`services/${serviceId}/cancel`, null);
  }

  approve(serviceId, body?: { expect_start_time: string; }) {
    return this.http.post(`services/${serviceId}/approve`, body);
  }

  reject(serviceId, body?: { note: string; }) {
    return this.http.post(`services/${serviceId}/reject`, body);
  }

  assignTo(serviceId, engineers, eta) {
    return this.http.post(`services/${serviceId}/assign-to`, {engineers, eta});
  }

  checkValid(serviceId, type = '') {
    return this.http.post(`services/${serviceId}/valid`, {type});
  }

  // service uplift
  storeUplift(serviceId, {description = '', user_ids = [], budget = 0}) {
    return this.http.post(`services/${serviceId}/uplifts`, {description, user_ids, budget});
  }

  uplifts(serviceId) {
    return this.http.get(`services/${serviceId}/uplifts`);
  }

  updateCompanyServiceRelation(data) {
    return this.http.post(`services/company_service_relation/update`, data);
  }
}
