import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { QuotationOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ApplicationService } from '@shared/services/api/application.service';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { PopupService } from '@shared/services/popup.service';
import { QuotationModel } from '@app/shared/models/quotation.model';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html'
})
export class ApplicationEditComponent implements OnInit {
  public saleDisplayColumns = [
    { display: 'Service', key: 'service_id', useColor: false },
    { display: 'Type', key: 'item_type', useColor: false },
    { display: 'Name', key: 'item_name', useColor: false },
    { display: 'Desc', key: 'item_description', useColor: false },
    { display: 'Total', key: 'item_total_price', useColor: false },
    { display: 'Unit Price', key: 'item_unit_price', useColor: false },
    { display: 'Unit Duration', key: 'item_unit_duration', useColor: false },
    { display: 'Quantity', key: 'item_quantity', useColor: false },
    { display: 'Operator', key: 'operator', useColor: false },
    { display: 'Status', key: 'item_status', useColor: true },
  ];

  @Input() id;
  @Input() inPopup = false;
  public item: QuotationModel;

  constructor(
    private _service: ApplicationService,
    private route: ActivatedRoute,
    public displayColumnsService: DisplayColumnsService,
    private popup: PopupService,
    private loader: AppLoaderService
  ) {
  }

  ngOnInit() {
    if (!this.id) {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.show(this.id);
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
      });
  }

  openEditServicePopUp(row) {
    this.popup.openServiceEditPage(row.id)
      .afterClosed()
      .subscribe();
  }
}
