import {Component, Input, OnInit} from '@angular/core';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {FormControl, FormGroup} from '@angular/forms';
import {CompanyService} from '@shared/services/api/company.service';
import {NavigationEnd, Router} from '@angular/router';
import {GlobalSettingConfig} from '@app/shared/interfaces/global-setting-config';
import {NotifyService} from '@app/shared/services/notify.service';
import {CompanyModel} from '@shared/models/company.model';

@Component({
  selector: 'app-global-setting',
  templateUrl: './global-setting.component.html',
  styleUrls: ['./global-setting.component.scss']
})
export class GlobalSettingComponent implements OnInit {
  @Input() globalSettingPanel;
  public companies: CompanyModel[];
  public settings = new FormGroup({
    client: new FormControl(),
    pageStartTimeText: new FormControl(),
    pageEndTimeText: new FormControl(),
    useCompanyId: new FormControl(),
  });

  constructor(
    private globalSettingService: GlobalSettingService,
    private toast: NotifyService,
    public companyService: CompanyService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.globalSettingPanel.close();
      }
    });
    this.initConfig();
  }

  initConfig() {
    // listen config change
    this.globalSettingService.config$.subscribe((config: GlobalSettingConfig) => {
      console.log('config$.subscribe');
      this.companies = [...config.companies];
      this.settings.patchValue({
        client: config.client,
        pageStartTimeText: new Date(config.pageStartTimestamp),
        pageEndTimeText: new Date(config.pageEndTimestamp),
        useCompanyId: +config.useCompanyId
      }, {emitEvent: false, onlySelf: true});
    });

    this.settings.valueChanges.subscribe((change) => {
      console.log('settings change', change);
      let client = change.client;
      if (change.useCompanyId !== this.globalSettingService.config.useCompanyId) {
        client = null;
        this.settings.get('client').patchValue(null, {emitEvent: false});
      }
      this.globalSettingService.publishConfig({
        pageStartTimestamp: change.pageStartTimeText.getTime(),
        pageEndTimestamp: change.pageEndTimeText.getTime(),
        client: client,
        useCompanyId: change.useCompanyId,
      });
      this.toast.show('Save success.');
    });
  }
}
