import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-quotation-create-popup',
  template: `
    <div mat-dialog-title>
      <span>{{'Quotation Create' | translate}}</span>
      <div fxFlex></div>
      <a matTooltip="Close">
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
      </a>
    </div>
    <app-quotation-create mat-dialog-content [serviceId]="serviceId"
                          [inPopup]="true" (onSubmitted)="dialogRef.close(true)"></app-quotation-create>`
})

export class QuotationCreatePopupComponent implements OnInit {
  public serviceId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { serviceId: number },
    public dialogRef: MatDialogRef<QuotationCreatePopupComponent>,
  ) {
    if (this.data.serviceId) {
      this.serviceId = data.serviceId;
    }
  }

  ngOnInit() {
  }
}
