import {Injectable, HostListener, EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {getQueryParam} from '../../helpers/url.helper';

interface ILayoutConf {
  navigationPos?: string;   // side, top
  sidebarStyle?: string;    // full, compact, closed
  dir?: string;             // ltr, rtl
  layoutInTransition?: boolean;
  isMobile?: boolean;
  useBreadcrumb?: boolean;
  breadcrumb?: string;      // simple, title
  topbarFixed?: boolean;
  globalSetting?: boolean;
  customizer?: boolean;
  switchTable?: string;  // table and panel
  theme?: boolean;
  notification?: boolean;
}

interface ILayoutChangeOptions {
  duration?: number;
  transitionClass?: boolean;
}

interface IAdjustScreenOptions {
  browserEvent?: any;
  route?: string;
}


@Injectable()
export class LayoutService {
  public layoutConf: ILayoutConf;
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  public isMobile: boolean;
  public currentRoute: string;
  public fullWidthRoutes = ['shop'];
  public onRefresh = new EventEmitter();

  constructor(
    private router: Router
  ) {
    this.setAppLayout();
  }

  setAppLayout() {
    // ******** SET YOUR LAYOUT OPTIONS HERE *********
    this.layoutConf = {
      navigationPos: 'side',
      sidebarStyle: 'full',
      dir: 'ltr',
      useBreadcrumb: false,
      topbarFixed: false,
      breadcrumb: 'title',
      globalSetting: true,
      customizer: false,
      switchTable: 'panel',
      theme: false,
      notification: false
    };

    // ******* Only for demo purpose ***
    // this.setLayoutFromQuery();
    // **********************

    this.setLayoutFromCache();

  }

  publishLayoutChange(lc: ILayoutConf, opt: ILayoutChangeOptions = {}) {
    let duration = opt.duration || 250;
    if (!opt.transitionClass) {
      this.layoutConf = Object.assign(this.layoutConf, lc);
      this.layoutConfSubject.next(this.layoutConf);
    } else {
      this.layoutConf = Object.assign(this.layoutConf, lc, {layoutInTransition: true});
      this.layoutConfSubject.next(this.layoutConf);

      setTimeout(() => {
        this.layoutConf = Object.assign(this.layoutConf, {layoutInTransition: false});
        this.layoutConfSubject.next(this.layoutConf);
        localStorage.setItem('layout', JSON.stringify(this.layoutConf));
      }, duration);
    }
    localStorage.setItem('layout', JSON.stringify(this.layoutConf));
  }

  setLayoutFromQuery() {
    let layoutConfString = getQueryParam('layout');
    try {
      this.layoutConf = JSON.parse(layoutConfString);
    } catch (e) {
    }
  }

  setLayoutFromCache() {
    let layoutConfString = localStorage.getItem('layout');
    try {
      if (layoutConfString) {
        this.layoutConf = JSON.parse(layoutConfString);
      }
    } catch (e) {
    }
  }

  adjustLayout(options: IAdjustScreenOptions = {}) {
    let sidebarStyle: string;
    this.isMobile = this.isSm();
    this.currentRoute = options.route || this.currentRoute;
    sidebarStyle = this.isMobile ? 'closed' : 'full';
    if (this.currentRoute) {
      this.fullWidthRoutes.forEach(route => {
        if (this.currentRoute.indexOf(route) !== -1) {
          sidebarStyle = 'closed';
        }
      });
    }
    if (this.layoutConf.isMobile !== this.isMobile || this.layoutConf.sidebarStyle !== sidebarStyle) {
      this.publishLayoutChange({
        isMobile: this.isMobile,
        sidebarStyle: sidebarStyle
      });
    }
  }

  isSm() {
    return window.matchMedia(`(max-width: 959px)`).matches;
  }
}
