import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '@shared/services/system/theme.service';
import { egretAnimations } from '@shared/animations/egret-animations';
import { Router } from '@angular/router';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import 'rxjs-compat/add/operator/finally';
import { LayoutService } from '@shared/services/system/layout.service';
import { NotifyService } from '@app/shared/services/notify.service';


@Component({
  selector: 'app-base-create',
  template: ``,
  animations: egretAnimations
})
export class BaseCreateComponent implements OnInit, OnDestroy {

  public loading$ = false;  // table data loading

  public itemName = '';

  public name = '';

  public urlPrefix = '';

  public _serviceClass;

  // providers

  protected theme: ThemeService;
  protected router: Router;
  protected confirmService: AppConfirmService;
  protected loader: AppLoaderService;
  protected toast: NotifyService;
  protected _service: ApiBaseService | any;
  protected layout: LayoutService;

  constructor(
    protected injector: Injector
  ) {
  }

  ngOnInit() {
    this.theme = this.injector.get(ThemeService);
    this.router = this.injector.get(Router);
    this.confirmService = this.injector.get(AppConfirmService);
    this.loader = this.injector.get(AppLoaderService);
    this.toast = this.injector.get(NotifyService);
    this.layout = this.injector.get(LayoutService);
  }

  ngOnDestroy() {
  }

  gotoTable() {
    this.router.navigate([`/${this.urlPrefix}`]);
  }
}
