import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { dashboardConfigs } from '@shared/config';
import { HttpClient } from '@angular/common/http';
import { ModelAttributeOptionService } from './model-attribute-option.service';
import { ModelAttributeOptions } from '@app/shared/models/options';

@Injectable()
export class QuotationService extends ApiBaseService {
  route_prefix = 'quotations';

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Type'
    },
    dashboard_type: {
      value: { item: '', group: '' },
      nullable: true,
      useGroup: true,
      groups: dashboardConfigs.quotation,
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
      display_name: 'Dashboard Type'
    }
  };

  constructor(
    public http: HttpClient,
    private maoService: ModelAttributeOptionService,

  ) {
    super(http);
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data['quotation']) {
          this.conditions.status.items = data.quotation.status;
          this.conditions.type.items = data.quotation.type;
        }
      });
  }

  searchCompanies() {
    return this.http.post('company/get-companies', { type: 'client' });
  }

  getBuildings(client_id) {
    return this.http.post('company/get-buildings', { company_id: client_id });
  }

  approval(status, quotation_id) {
    return this.http.post('quotation/approval', { status, quotation_id });
  }

  getQuotationItems(quotation_id) {
    return this.http.post('quotation/get-quotation-items', { quotation_id });

  }

  updateService(id, item) {
    return this.http.post('service/update', { id, ...item });
  }

  updateQuotationItem(id, item) {
    return this.http.post('quotation/update-quotation-item', { id, ...item });
  }

  removeService(row) {
    return this.http.post('service/delete', { ids: [row.id] });
  }

  removeQuotationItem(row) {
    return this.http.post('quotation/delete-quotation-item', { ids: [row.id] });
  }

  getItems(quotation_id) {
    return this.http.get(`${this.route_prefix}/${quotation_id}/items`);
  }

  getServices(quotation_id) {
    return this.http.get(`${this.route_prefix}/${quotation_id}/services`);
  }

  approve(quotationId) {
    return this.http.post(`${this.route_prefix}/${quotationId}/approve`, null);
  }

  reject(quotationId) {
    return this.http.post(`${this.route_prefix}/${quotationId}/reject`, null);
  }
}
