import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Conditions} from '@shared/models/conditions.model';
import {HttpClient} from '@angular/common/http';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';

@Injectable()
export class FinanceService {
  route_prefix = 'finances';

  public conditions: Conditions;

  constructor(
    private http: HttpClient
  ) {
  }

  index(params: LaravelPageRequest | any) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  getServiceFinanceDetail(serviceId) {
    return this.http.get('finances/service-finance-detail', {params: {service_id: serviceId}});
  }

  awaitingCosts() {
    return this.http.get(`${this.route_prefix}/awaiting-costs`);
  }

  pendingSubmission() {
    return this.http.get(`${this.route_prefix}/pending-submission`);
  }

  applications() {
    return this.http.get(`${this.route_prefix}/applications`);
  }

  certificates() {
    return this.http.get(`${this.route_prefix}/certificates`);
  }

  submitApplication(serviceId, {materials, plants, others} = {materials: [], plants: [], others: []}, setTotal = null) {
    return this.http.post(`${this.route_prefix}/submit-application`, {service_id: serviceId, materials, plants, others, set_total: setTotal});
  }

  moveToAwaitingCosts(serviceId) {
    return this.http.post(`${this.route_prefix}/move-to-awaiting-costs`, {service_id: serviceId});
  }

  budgetDate(companyId) {
    return this.http.get(`${this.route_prefix}/budget-date/${companyId}`);
  }

  generateCertificateFile(invoiceId) {
    return this.http.post(this.route_prefix + '/' + invoiceId + '/generate-certificate-file', null);
  }

  uploadCertificateFileUrl(invoiceId) {
    return environment.apiURL + environment.adminPrefix + this.route_prefix + '/' + invoiceId + '/upload-certificate-file';
  }

  getPeriod() {
    return this.http.get('finances/period');
  }
}
