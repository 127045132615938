import {GlobalSettingService} from './../../../../services/system/global-setting.service';
import {Component, OnInit, Injector, Input, OnDestroy} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {QuotationService} from '@shared/services/api/quotation.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';
import {LayoutService} from '@shared/services/system/layout.service';

@Component({
  selector: 'app-quotation-table',
  templateUrl: './quotation-table.component.html',
  animations: egretAnimations
})
export class QuotationTableComponent extends BaseTableComponent implements OnInit, OnDestroy {
  @Input() dashboardType = '';
  @Input() dashboardGroup = '';
  @Input() inPopup = false;
  conditions = this.quotationService.conditions;

  public urlPrefix = 'quotation';
  public items: any[];

  constructor(
    protected injector: Injector,
    public quotationService: QuotationService,
    public _service: QuotationService,
    private globalSettingService: GlobalSettingService,
    public layout: LayoutService,
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.dashboardType && this.dashboardGroup) {
      this.conditions.dashboard_type.value = {
        item: this.dashboardType,
        group: this.dashboardGroup
      };
    }
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }

  ngOnDestroy() {
    this.destroy = true;
  }
}
