import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { DashboardService } from '@shared/services/api/dashboard.service';
import { ThemeService } from '@shared/services/system/theme.service';
import { PopupService } from '@shared/services/popup.service';
import * as _ from 'lodash';
import { LayoutService } from '@shared/services/system/layout.service';
import { dashboardConfigs } from '@shared/config';

@Component({
  selector: 'app-quotation-dashboard',
  templateUrl: './quotation-dashboard.component.html',
  animations: egretAnimations
})
export class QuotationDashboardComponent implements OnInit, OnDestroy {
  public loading = false;
  _ = _;
  public dashboard;
  public displays = dashboardConfigs.quotation;
  public onRefreshSub;
  @Output() onClickDashboard = new EventEmitter();

  constructor(
    private dashboardService: DashboardService,
    private layout: LayoutService,
    public theme: ThemeService
  ) {
  }

  ngOnInit() {
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.dashboardService.getQuotationDashboard()
      .finally(() => this.loading = false)
      .subscribe((data) => {
        this.dashboard = data;
      });
  }

  ngOnDestroy() {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  openTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({ group: dashboardGroup, item: dashboardType });
  }
}
