import {ApproveServiceService} from '@shared/services/system/approve-service/approve-service.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceService} from '@shared/services/api/service.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';

import {AssetModel} from '@shared/models/asset.model';
import {AssetService} from '@shared/services/api/asset.service';
import {PopupService} from '@shared/services/popup.service';
import {LayoutService} from '@shared/services/system/layout.service';
import {ServiceModel} from '@shared/models/service.model';
import {BuildingModel} from '@shared/models/building.model';
import {BuildingService} from '@shared/services/api/building.service';
import {PurchaseOrderModel} from '@shared/models/purchase-order.model';
import {QuotationModel} from '@shared/models/quotation.model';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {RejectServiceService} from '@app/shared/services/system/reject-service/reject-service.service';
import {ServiceCustomizeService} from '@app/shared/components/common/service-customize.service';
import {ServiceExtraService} from '@app/shared/components/common/service-extra.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {UserService} from '@shared/services/api/user.service';
import { parseDate } from '@app/shared/helpers/url.helper';
import { TimeConvertService } from '@app/shared/services/time-convert.service';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  providers: [
    ServiceCustomizeService,
    ServiceExtraService,
  ],
})
export class ServiceEditComponent implements OnInit, OnDestroy {
  _ = _;
  loadingAssets = false;
  companyServiceRelation = null;
  public saleDisplayColumns = [
    {display: 'Service', key: 'service_id', useColor: false},
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Desc', key: 'item_description', useColor: false},
    {display: 'Total', key: 'item_total_price', useColor: false},
    {display: 'Unit Price', key: 'item_unit_price', useColor: false},
    {display: 'Unit Duration', key: 'item_unit_duration', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Operator', key: 'operator', useColor: false},
    {display: 'Status', key: 'item_status', useColor: true},
  ];
  public relationServicesDisplayedColumns = [
    {display: 'Id', key: 'id', useColor: false},
    {display: 'Client', key: 'client_name', useColor: false},
    {display: 'Building', key: 'building_name', useColor: false},
    {display: 'Relation Type', key: 'relation_type', useColor: false},
    {display: 'Type', key: 'service_type', useColor: false},
    {display: 'Discipline', key: 'discipline', useColor: false},
    {display: 'Contractors', key: 'contractors', useColor: false},
    {display: 'Creator', key: 'create_user_name', useColor: false},
    {display: 'Status', key: 'status', useColor: true},
    {display: 'Priority', key: 'severity', useColor: false}
  ];
  @Input() id = null;
  @Input() inPopup = false;
  roomIdCtl = new FormControl(null);
  itemForm = new FormGroup({
    engineers: new FormControl([]),
    asset_ids: new FormControl([]),
    expect_start_time: new FormControl(''),
  });

  relationForm = new FormGroup({
    id: new FormControl(null, Validators.required),
    expect_end_time: new FormControl(null, Validators.required),
    expect_start_time: new FormControl(null, Validators.required),
  });

  public item: ServiceModel;

  public get engineers() {
    return this.userService.engineers;
  };

  public rooms = [];
  public assets: AssetModel[];
  public building: BuildingModel;
  public quotations: QuotationModel[] = [];
  public purchaseOrders: PurchaseOrderModel[] = [];
  private onRefreshSub;

  constructor(
    private _service: ServiceService,
    private loader: AppLoaderService,
    private router: Router,
    private maoService: ModelAttributeOptionService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private assetService: AssetService,
    public popup: PopupService,
    public layout: LayoutService,
    public buildingService: BuildingService,
    public displayColumnsService: DisplayColumnsService,
    public globalSetting: GlobalSettingService,
    private rejectService: RejectServiceService,
    private approveService: ApproveServiceService,
    public customizeService: ServiceCustomizeService,
    public extraService: ServiceExtraService,
    private userService: UserService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.initData();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  initData() {
    this.show();
  }

  fillForm(item) {
    this.itemForm.patchValue({
      engineers: item.engineers.map(engineers => engineers.id),
      asset_ids: _.get(item, 'asset_ids', []),
    });
    this.roomIdCtl.setValue(_.get(item, 'data.room_id', null));
    this.extraService.setFormGroup(_.get(item, 'data.extra', {}));
    this.customizeService.setByObject(_.get(item, 'data.customize', {}));
    this.getBuilding(item.building_id);
    (_.get(item, 'company_service_relations', [])).some((relation: any) => {
      if (relation.company_id == this.globalSetting.config.useCompanyId) {
        this.companyServiceRelation = relation;
        this.relationForm.patchValue({
          id: relation.id,
          expect_end_time: parseDate(relation, 'expect_end_time'),
          expect_start_time: parseDate(relation, 'expect_start_time'),
        });

        return true;
      }

      return false;
    })
  }

  getBuilding(buildingId) {
    let loader = this.loader.open();
    this.buildingService.show(buildingId)
      .finally(() => loader.close())
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, 'data.rooms', []);
      });
  }

  getAssets(building_id) {
    let loader = this.loader.open();
    this.loadingAssets = true;
    this.assetService.byBuildingId(building_id)
      .finally(() => {
        loader.close();
        this.loadingAssets = false;
      })
      .subscribe((data: [AssetModel]) => {
        this.assets = data;
      });
  }

  openQuotationCreatePopup() {
    this.popup.openQuotationCreatePopup(this.id).afterClosed().subscribe((res) => {
      if (res) {
        this.refresh();
      }
    });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.quotations = data.quotations;
        this.purchaseOrders = data.purchaseOrders;
        this.fillForm(data);
        if (data.service_type === 'ppm') {
          this.getAssets(data.building_id);
        }
      });
  }

  updateCompanyServiceRelation() {
    let formValue = this.relationForm.value;
    formValue['expect_end_time'] = this.timeConvertService.convert(formValue['expect_end_time']);
    formValue['expect_start_time'] = this.timeConvertService.convert(formValue['expect_start_time']);

    let loader = this.loader.open();
    this._service.updateCompanyServiceRelation(formValue)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.toast.show('Update success');
      });
  }

  gotoTable() {
    this.router.navigate(['/service']);
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  refresh() {
    this.initData();
  }

  openQuotationEditPopup($event) {
    this.popup.openQuotationEditPage($event.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  complete() {
    this.confirmService.confirm({message: `Are you sure completed service ${this.id} ?`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this._service.complete(this.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Complete success');
              this.refresh();
            });
        }
      });
  }

  accept() {
    this.approveService.show().subscribe((res: any) => {
      if (res) {
        let loader = this.loader.open();
        this._service.approve(this.id, {...res})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Approve success');
            this.refresh();
          });
      }
    });
  }

  reject() {
    this.rejectService.show().subscribe((res: undefined | { note: string }) => {
      if (res) {
        let loader = this.loader.open();
        this._service.reject(this.id, {...res})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Reject success');
            this.layout.onRefresh.emit();
          });
      }
    });
  }

  assignTo() {
    this.confirmService.confirm({message: `Are you sure assign to engineers?`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this._service.assignTo(
            this.id,
            this.itemForm.get('engineers').value,
            this.itemForm.get('expect_start_time').value
          )
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Assign success');
              this.refresh();
            });
        }
      });
  }

  openEditServicePopup($event) {
    this.popup.openServiceEditPage($event.id);
  }

  openEditServiceVisit(row) {
    this.popup.openServiceVisitEditPage(row.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  openPurchaseOrderEditPopup(row) {
    this.popup.openPurchaseOrderEditPopup(row.id)
      .afterClosed()
      .subscribe();
  }

  searchRoom(term, item) {
    return item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }
}
