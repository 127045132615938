import {Routes} from '@angular/router';
import {AdminLayoutComponent} from '@shared/components/system/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from '@shared/components/system/layouts/auth-layout/auth-layout.component';
import {AuthGuard} from '@shared/services/system/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: {title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: {title: 'Dashboard', breadcrumb: 'DASHBOARD'}
      },
      {
        path: 'service',
        loadChildren: './views/service/service.module#ServiceModule',
        data: {title: 'Service', breadcrumb: 'Service'}
      },
      {
        path: 'quotation',
        loadChildren: './views/quotation/quotation.module#QuotationModule',
        data: {title: 'Quotation', breadcrumb: 'Quotation'}
      },
      {
        path: 'finance',
        loadChildren: './views/finance/finance.module#FinanceModule',
        data: {title: 'Finance', breadcrumb: 'Finance'}
      },
      {
        path: 'timesheet',
        loadChildren: './views/timesheet/timesheet.module#TimesheetModule',
        data: {title: 'Timesheet', breadcrumb: 'Timesheet'}
      },
      {
        path: 'search/:keyword',
        loadChildren: './views/search/search.module#SearchModule',
        data: {title: 'Search', breadcrumb: 'Search'}
      },
      {
        path: 'profile',
        loadChildren: './views/profile/profile.module#ProfileModule',
        data: {title: 'Profile', breadcrumb: 'PROFILE'}
      },
      {
        path: '',
        loadChildren: './views/settings/settings.module#SettingsModule',
        data: {title: 'Settings', breadcrumb: 'Settings'}
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

