import {UploadInvoice} from './../../views/finance/upload-invoice.component';
import {Injectable} from '@angular/core';
import {ServiceVisitEditPopupComponent} from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';
import {MatDialog} from '@angular/material';
import {NoteEditPopupComponent} from '@shared/components/business/note/note-edit/note-edit-popup.component';
import {DocumentEditPopupComponent} from '@shared/components/business/document/document-edit/document-edit-popup.component';
import {ServiceEditPopupComponent} from '@shared/components/business/service/service-edit/service-edit-popup.component';
import {QuotationItemCreatePopupComponent} from '@shared/components/business/quotation/quotation-item-create/quotation-item-create-popup.component';
import {QuotationItemEditPopupComponent} from '@shared/components/business/quotation/quotation-item-edit/quotation-item-edit-popup.component';
import {QuotationCreatePopupComponent} from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import {QuotationEditPopupComponent} from '@shared/components/business/quotation/quotation-edit/quotation-edit-popup.component';
import {PurchaseOrderEditPopupComponent} from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit-popup.component';

@Injectable()
export class PopupService {
  constructor(
    public dialog: MatDialog,
  ) {
  }

  openServiceVisitEditPage(visit_id) {
    return this.dialog.open(ServiceVisitEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: visit_id}
    });
  }

  openPurchaseOrderEditPopup(id) {
    return this.dialog.open(PurchaseOrderEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: id}
    });
  }

  openNoteEditPage(note_id) {
    return this.dialog.open(NoteEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: note_id}
    });
  }

  openDocumentEditPage(document_id) {
    return this.dialog.open(DocumentEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: document_id}
    });
  }

  openServiceEditPage(service_id) {
    return this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      height: '90vh',
      disableClose: false,
      data: {id: service_id}
    });
  }

  openQuotationItemCreatePage(quotation_id, services) {
    return this.dialog.open(QuotationItemCreatePopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: quotation_id, services}
    });
  }

  openQuotationItemEditPage(quotation_item_id) {
    return this.dialog.open(QuotationItemEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: quotation_item_id, view: false}
    });
  }

  openQuotationCreatePopup(serviceId) {
    return this.dialog.open(QuotationCreatePopupComponent, {
      width: '80%',
      disableClose: true,
      data: {serviceId}
    });
  }

  openQuotationEditPage(quotationId) {
    return this.dialog.open(QuotationEditPopupComponent, {
      width: '80%',
      disableClose: true,
      data: {id: quotationId}
    });
  }

  openUploadInvoice(invoiceId) {
    return this.dialog.open(UploadInvoice, {
      disableClose: false,
      data: {invoiceId}
    });
  }
}
