import {Component, Input, OnInit} from '@angular/core';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {InvoiceService} from '@shared/services/api/invoice.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {PopupService} from '@shared/services/popup.service';
import {InvoiceModel} from '@shared/models/invoice.model';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html'
})
export class InvoiceEditComponent implements OnInit {
  @Input() id;
  @Input() inPopup = false;
  public item: InvoiceModel;
  constructor(
    private _service: InvoiceService,
    private loader: AppLoaderService,
    public displayColumnsService: DisplayColumnsService,
    private popup: PopupService
  ) {
  }

  ngOnInit() {
    this.show(this.id);
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
      });
  }

  openEditServicePopUp(row) {
    this.popup.openServiceEditPage(row.id)
      .afterClosed()
      .subscribe();
  }
}
