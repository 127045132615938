import {Component, Input, OnInit} from '@angular/core';
import {PurchaseOrderService} from '@shared/services/api/purchase-order.service';
import {PurchaseOrderModel} from '@shared/models/purchase-order.model';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {DisplayColumnsService} from '@app/shared/services/display-columns.service';

@Component({
  selector: 'app-purchase-order-edit',
  templateUrl: './purchase-order-edit.component.html'
})
export class PurchaseOrderEditComponent implements OnInit {
  @Input() id;
  public item: PurchaseOrderModel;

  constructor(
    public _service: PurchaseOrderService,
    private loader: AppLoaderService,
    public displayColumnsService: DisplayColumnsService
  ) {
  }

  ngOnInit() {
    this.show();
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
      });
  }

}
