import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TimesheetService {
  constructor(
    public http: HttpClient
  ) {
  }

  calculation(engineerId, startDateTime, endDateTime) {
    return this.http.post(`timesheet/calculation/${engineerId}`, {start_datetime: startDateTime, end_datetime: endDateTime});
  }

  index(engineerId, startDateTime, endDateTime) {
    return this.http.post(`timesheet/index/${engineerId}`, {start_datetime: startDateTime, end_datetime: endDateTime});
  }

  delete(salaryId) {
    return this.http.delete(`salaries/${salaryId}`);
  }
}
