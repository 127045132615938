import {EventEmitter} from '@angular/core';

export const dashboardConfigs = {
  service: {
    reactive: {
      display: 'Reactive',
      items: [
        {key: 'logged'},
        {key: 'accepted'},
        {key: 'assigned'},
        {key: 'in_progress'},
        {key: 'overdue'},
      ]
    },
    ppm: {
      display: 'PPM',
      items: [
        {key: 'logged'},
        {key: 'accepted'},
        {key: 'to_be_assigned_to_engineer'},
        {key: 'in_progress'},
        {key: 'completed_awaiting_cert'},
        {key: 'overdue'},
      ]
    },
    quote_request: {
      display: 'Quote Request',
      items: [
        {key: 'logged'},
        {key: 'accepted'},
        {key: 'outstanding_quotes'},
      ]
    },
    quote_approval: {
      display: 'Quote Approval',
      items: [
        {key: 'logged'},
        {key: 'accepted'},
        {key: 'in_progress'},
        {key: 'to_be_assigned_to_engineer'},
        {key: 'overdue'},
      ]
    },
  },
  quotation: {
    quotation: {
      display: 'Quotation',
      items: [
        {key: 'quote_submitted'},
        {key: 'quotes_approved_awaiting_completion_approved'},
        {key: 'quotes_uploaded_today'},
      ]
    }
  },
};

export const financeConfigs = {
  awaitingCosts: {
    display: 'Awaiting Costs',
    items: [
      {key: 'id', display: 'Id', clickable: true, clickevent: new EventEmitter},
      {key: 'submit_application', noData: true, display: 'SUBMIT APPLICATION', clickable: true, clickevent: new EventEmitter},
      {key: 'client_name', display: 'Client'},
      {key: 'building_name', display: 'Building'},
      {key: 'service_type', display: 'Service Type'},
      {key: 'discipline', display: 'discipline'},
      {key: 'due', display: 'Due'},
    ]
  },
  pendingSubmission: {
    display: 'Pending Submission',
    items: [
      {key: 'id', display: 'Id', clickable: true, clickevent: new EventEmitter},
      {key: 'move_to_awaiting_costs', noData: true, display: 'MOVE TO AWAITING COSTS', clickable: true, clickevent: new EventEmitter},
      {key: 'client_name', display: 'Client'},
      {key: 'building_name', display: 'Building'},
      {key: 'service_type', display: 'Service Type'},
      {key: 'discipline', display: 'Discipline'},
      {key: 'total', display: 'Total'},
    ]
  },
  application: {
    display: 'Application',
    items: [
      {key: 'id', display: 'Id', clickable: true, clickevent: new EventEmitter},
      {key: 'view_application', noData: true, display: 'VIEW APPLICATION', clickable: true, clickevent: new EventEmitter},
      {key: 'total', display: 'total'},
    ]
  },
  certificates: {
    display: 'Certificates',
    items: [
      {key: 'id', display: 'Id', clickable: true, clickevent: new EventEmitter},
      {key: 'view_invoice', noData: true, display: 'VIEW INVOICE', clickable: true, clickevent: new EventEmitter},
      {key: 'view_certificate', noData: true, display: 'VIEW CERTIFICATE', clickable: true, clickevent: new EventEmitter},
      {key: 'upload_invoice', noData: true, display: 'UPLOAD INVOICE', clickable: true, clickevent: new EventEmitter},
      {key: 'total', display: 'Total'},
    ]
  }
};

export const tableReplaceValueColumn = {
  item_total_price: (value) => {
    return parseFloat(value).toFixed(2);
  },
  total: (value) => {
    return parseFloat(value).toFixed(2);
  },
};
