import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-service-edit-popup',
  template: `
  <app-page-card-header title="SERVICE {{data?.id}}" (onBack)="dialogRef.close()">
  </app-page-card-header>
    <app-service-edit [id]="data.id" [inPopup]="true"></app-service-edit>`
})
export class ServiceEditPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; },
    public dialogRef: MatDialogRef<ServiceEditPopupComponent>,
  ) {
  }

  ngOnInit() {
  }
}
