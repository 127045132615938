import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {CompanyOptions} from '@shared/models/options';
import {MatSnackBar} from '@angular/material';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {Router} from '@angular/router';
import {UserService} from '@shared/services/api/user.service';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class UserCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemForm: FormGroup;

  constructor(
    private _service: UserService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private router: Router,
    private global_service: GlobalSettingService
  ) { }

  ngOnInit() {
    this.buildItemForm();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      name: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', Validators.required],
      user_identity: [''],
      ui_entries: [''],
      address1: [''],
      address2: [''],
      town: [''],
      country: ['United Kingdom'],
      city: [''],
      postcode: ['']
    });
  }

  submit() {
    if (this.itemForm.valid) {
      let loader = this.loader.open();
      this._service.store(this.itemForm.value)
        .finally(() => loader.close())
        .subscribe(() => {
          this.onSubmitted.emit();
          this.snack.open('User Added!', 'OK', { duration: 4000 });
          SimpleReuseStrategy.deleteRouteSnapshot('///settings/user/create');
        });
    }
  }

  gotoTable() {
    this.router.navigate(['/settings/user']);
  }
}
