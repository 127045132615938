import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {LayoutService} from '@shared/services/system/layout.service';
import {CompanyModel} from '@shared/models/company.model';
import {GlobalSettingConfig} from '@app/shared/interfaces/global-setting-config';
import {get, has, set} from 'lodash';
import {subYears, addYears} from 'date-fns';
import {UserModel} from '@app/shared/models/user.model';
import {CacheService, CacheKeys} from '../cache.service';
import {ReplaySubject} from 'rxjs';

@Injectable()
export class GlobalSettingService {
  private cacheKey = CacheKeys.global_setting;

  // default config value
  public defaultConfig: GlobalSettingConfig = {
    apiUri: environment.apiURL + environment.adminPrefix,
    pageStartTimestamp: subYears(Date.now(), 2).getTime(),
    pageEndTimestamp: addYears(Date.now(), 2).getTime(),
    client: null,
    useCompanyId: null,
    companies: [],
    user: new UserModel,
    token: ''
  };
  public oldConfig: GlobalSettingConfig;
  public config: GlobalSettingConfig;
  public config$ = new ReplaySubject(1);
  public useCompanyId$ = new ReplaySubject(1);

  constructor(
    private layout: LayoutService,
    private cache: CacheService,
  ) {
    this.initConfigByCache();
    this.config$.subscribe((config: GlobalSettingConfig) => {
      this.saveConfigToCache();
      this.saveConfigToServer();
      if (config['useCompanyId'] && config['useCompanyId'] !== this.oldConfig.useCompanyId) {
        this.useCompanyId$.next(config['useCompanyId']);
      }
    });
  }

  public saveConfigToCache() {
    this.cache.set(this.cacheKey, this.config);
  }

  public saveConfigToServer() {
    // this.publicService.setSetting({settings: this.config}).subscribe();
  }

  public initConfigByCache(config: GlobalSettingConfig = {}) {
    this.oldConfig = {...this.config};
    // load config by cache
    if (Object.keys(config).length === 0) {
      this.config = this.cache.get(this.cacheKey, {...this.defaultConfig});
    } else {
      this.config = {...config};
    }
    this.publish();
    this.saveConfigToCache();
  }

  public clearConfig() {
    this.oldConfig = {...this.config};
    this.cache.remove(this.cacheKey);
    this.config = {...this.defaultConfig};
    this.publish();
  }

  private publish() {
    this.config$.next(this.config);
  }

  public setConfig(path, value = null, options: { onEvent?: boolean } = {onEvent: true}) {
    if (has(this.config, path)) {
      this.oldConfig = this.config;
      set(this.config, path, value);
      if (options.onEvent) {
        this.publish();
      }
    }
  }

  public publishConfig(lc: GlobalSettingConfig) {
    this.oldConfig = this.config;
    this.config = Object.assign(this.config, lc);
    this.publish();
  }

  public getConfig(path, def: any = '') {
    return get(this.config, path, def);
  }

  public getCompanyName() {
    let findCompany = this.config.companies.find(company => +company.id === +this.config.useCompanyId);
    if (findCompany) {
      return findCompany['name'];
    } else {
      return '';
    }
  }
}
