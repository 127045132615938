import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

import { FormControl, FormGroup } from '@angular/forms';
import { SalaryTimeService } from '@shared/services/api/salary-time.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import 'rxjs-compat/add/operator/finally';
import { NotifyService } from '../../notify.service';

@Component({
  selector: 'app-change-timesheet-time',
  template: // html
    `
    <h1 mat-dialog-title translate>Change time</h1>
    <div>
      <mat-form-field>
        <mat-select [formControl]="form.controls['type']" placeholder="Type">
          <mat-option *ngFor="let type of types" [value]="type">{{type | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!--Start Time-->
      <md2-datepicker
        type="datetime"
        class="full-width"
        placeholder="Start Time"
        [formControl]="form.controls['start_time']"
        required>
      </md2-datepicker>
      <!--End Time-->
      <md2-datepicker
        type="datetime"
        class="full-width"
        placeholder="End Time"
        [formControl]="form.controls['end_time']"
        required>
      </md2-datepicker>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="add()" color="accent" translate>
      <mat-icon>add</mat-icon>Add</button>
      <button mat-raised-button (click)="update()" color="primary" translate>
      <mat-icon>update</mat-icon>Update</button>
      <button mat-raised-button (click)="delete()" color="warn" translate>
      <mat-icon>delete</mat-icon>Delete</button>
    </div>
  `
})
export class ChangeTimesheetTimeComponent implements OnInit {
  types = ['service_standard_hour', 'service_overtime_hour', 'travel'];
  form = new FormGroup({
    start_time: new FormControl(),
    end_time: new FormControl(),
    type: new FormControl(),
    engineer_id: new FormControl(),
    old_salary_time_id: new FormControl(),
    salary_id: new FormControl(),
  });

  constructor(
    public dialogRef: MatDialogRef<ChangeTimesheetTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { timeId: string & number },
    public salaryTimeService: SalaryTimeService,
    public auth: AuthGuard,
    public toast: NotifyService,
    private loader: AppLoaderService
  ) {
  }

  ngOnInit(): void {
    let salaryTime = this.data['salaryTime'];
    this.form.patchValue({
      start_time: salaryTime.start_time,
      end_time: salaryTime.end_time,
      type: salaryTime.data.service_action,
      engineer_id: salaryTime.user_id,
      old_salary_time_id: salaryTime.id,
      salary_id: salaryTime.salary_id
    });
  }

  update() {
    let loader = this.loader.open();
    this.salaryTimeService.update(this.form.value.old_salary_time_id, this.form.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.dialogRef.close(true);
        this.toast.show('Update success.');
      });
  }

  add() {
    let loader = this.loader.open();
    this.salaryTimeService.store(this.form.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.dialogRef.close(true);
        this.toast.show('Add success.');
      });
  }

  delete() {
    let loader = this.loader.open();
    this.salaryTimeService.destroy(this.form.value.old_salary_time_id)
      .finally(() => loader.close())
      .subscribe(() => {
        this.dialogRef.close(true);
        this.toast.show('Delete success.');
      });
  }
}

