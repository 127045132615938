import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {UserService} from '@shared/services/api/user.service';
import {UserModel} from '@shared/models/user.model';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  public loading$ = false;
  @Input() id;

  public itemForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    email: new FormControl({value: '', disabled: true}),
    ui_entries: new FormControl([], [Validators.required]),
    user_identity: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    password: new FormControl(),
  });
  public item: UserModel;

  constructor(
    public userService: UserService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.show(this.id);
  }

  public entries() {
    let entries = [];
    if (this.userService.userCompanyOptions) {
      const type = this.userService.userCompanyOptions['type'];
      entries = type.filter(item => item.option_name !== 'user_company_type');
    }
    return entries;
  }

  public canManagementEntries() {
    let entries = [];
    if (this.userService.userCompanyOptions) {
      const type = this.userService.userCompanyOptions['type'];
      entries = type.find(item => item.option_name === 'user_company_type')['data']['supplier'];
    }
    return entries;
  }

  public isInCanManagementEntries(value) {
    return this.canManagementEntries().find(item => item === value);
  }

  show(id) {
    let loader = this.loader.open();
    this.userService.show(id)
      ._finally(() => loader.close())
      .subscribe((data: UserModel) => {
        this.item = data;
        this.itemForm.patchValue(data);
      });
  }

  submit() {
    let loader = this.loader.open();
    this.userService.update(this.id, this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.router.navigate(['/settings/user']);
        this.snack.open('User Updated!', 'OK', {duration: 4000});
      });
  }

  refresh() {
    this.show(this.id);
  }
}
