export class BuildingOptions {
  type: { option_value: string }[];
  status: { option_value: string }[];
}

export class InvoiceItemOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
}

export class PurchaseOrderItemOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
}

export class QuotationItemOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
}

export class InvoiceOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
}

export class PurchaseOrderOptions {
  status: { option_value: string }[];
}

export class QuotationOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
}

export class ServiceOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
  service_type: { option_value: string }[];
  data: { option_value: string }[];
  severity: { option_value: string; option_name: string; }[];
  discipline: { option_value: string }[];
  data_groups: {
    cause: { option_value: string }[];
    finance_type: { option_value: string }[];
    response_time: { option_value: string }[];
  };
}

export class ServiceVisitOptions {
  status: { option_value: string }[];
  rate_type: { option_value: string }[];
  data_groups: {
    risk_assessment: { option_value: string }[];
  };
}

export class CompanyOptions {
  status: { option_value: string }[];
  type: { option_value: string }[];
}

export class AssetOptions {
  category: { option_value: string }[];

  data_groups: {
    asset_condition: { option_value: string }[],
    service_status: { option_value: string }[],
    vehicle_make: { option_value: string }[],
    compliant_status: { option_value: string }[],
  };
}

export class DocumentOptions {
  type: { option_value: string }[];
}

export class NoteOptions {
  severity: { option_value: string }[];
}

export class UserOptions {
  status: { option_value: string }[];
}

export class UserCompanyOptions {
  type: { option_value: string; option_name: string }[];
}

export class ModelAttributeOptions {
  quotation: QuotationOptions;
  service: ServiceOptions;
  building: BuildingOptions;
  purchase_order: PurchaseOrderOptions;
  invoice: InvoiceOptions;
  invoice_item: InvoiceItemOptions;
  company: CompanyOptions;
  service_visit: ServiceVisitOptions;
  purchase_order_item: PurchaseOrderItemOptions;
  quotation_item: QuotationItemOptions;
  asset: AssetOptions;
  document: DocumentOptions;
  note: NoteOptions;
  user: UserOptions;
  user_company: UserCompanyOptions;
}
