import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';

@Injectable()
export class BuildingService extends ApiBaseService {
  route_prefix = 'buildings';

  getOptions () {
    return this.http.post('building/get-options', null);
  }
}
