import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string;       // Possible values: link/dropDown/icon/separator/extLink
  name?: string;      // Used as display text for item and title for separator statu
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}

interface IChildItem {
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {
  defaultMenu: IMenuItem[] = [];
  separatorMenu: IMenuItem[] = [];
  iconMenu: IMenuItem[] = [
    {
      type: 'separator',
      name: 'Main Items'
    },
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'dashboard',
    },
    {
      name: 'Jobs',
      type: 'link',
      tooltip: 'Jobs',
      icon: 'build',
      state: 'service'
    },
    {
      name: 'QUOTATION',
      type: 'link',
      tooltip: 'Quotation',
      icon: 'assignment',
      state: 'quotation'
    },
    {
      name: 'Finance',
      type: 'link',
      tooltip: 'Finance',
      icon: 'description',
      state: 'finance',
    },
    {
      name: 'SETTINGS',
      type: 'dropDown',
      tooltip: '',
      icon: 'settings',
      state: '',
      sub: [
        { name: 'General', state: 'settings/general' },
        { name: 'Company', state: 'settings/company' },
        { name: 'User', state: 'settings/user' },
      ]
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon statu item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user statu.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'separator-menu':
        this.menuItems.next(this.separatorMenu);
        break;
      case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.defaultMenu);
    }
  }
}
