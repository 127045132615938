import {GlobalSettingService} from './../../../../services/system/global-setting.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ServiceService} from '@shared/services/api/service.service';
import {egretAnimations} from '@shared/animations/egret-animations';
import {ServiceModel} from '@shared/models/service.model';
import * as moment from 'moment';
import {ServiceTableService} from '@shared/components/business/service/service-table/service-table.service';
import {LayoutService} from '@shared/services/system/layout.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  animations: egretAnimations
})
export class ServiceTableComponent implements OnInit, OnDestroy {
  @Input() dashboardType = '';
  @Input() dashboardGroup = '';
  @Input() inPopup = false;
  @Output() onBack = new EventEmitter();

  public items: any[];
  protected destroy = false;

  constructor(
    private service: ServiceService,
    private globalSettingService: GlobalSettingService,
    public serviceTableService: ServiceTableService,
    private layout: LayoutService,
  ) {
  }

  ngOnInit() {
    if (this.dashboardType && this.dashboardGroup) {
      this.serviceTableService.conditions.dashboard_type.value = {
        item: this.dashboardType,
        group: this.dashboardGroup
      };
    }
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        this.serviceTableService.refresh();
      });
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.serviceTableService.loadPage().subscribe();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }
}
