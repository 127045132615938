import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { ApproveServiceComponent } from './approve-service.component';

@Injectable()
export class ApproveServiceService {

  constructor(private dialog: MatDialog) {
  }

  public show(): Observable<undefined | { expect_start_time: string, expect_end_time: string }> {
    return this.dialog.open(ApproveServiceComponent, {
      width: '380px',
      disableClose: false,
      data: {}
    }).afterClosed();
  }
}
