import {Injectable} from '@angular/core';
import {ServiceModel} from '@shared/models/service.model';
import {ModelAttributeOptions, ServiceOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Conditions} from '@shared/models/conditions.model';
import {dashboardConfigs} from '@shared/config';
import {LaravelPageResponse} from '@shared/models/laravel-page-response.model';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {Paginate} from '@shared/models/paginate.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceTableService {
  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    discipline: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Discipline'
    },
    severity: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_name',
      select_key: 'option_value',
      display_name: 'Priority'
    },
    service_type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'ServiceType'
    },
    dashboard_type: {
      value: {item: '', group: ''},
      nullable: true,
      useGroup: true,
      groups: dashboardConfigs.service,
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
      display_name: 'Dashboard Type'
    }
  };
  public items: ServiceModel[] = [];
  public paginator = new Paginate();
  public pageLoading = false;

  constructor(
    public http: HttpClient,
    public maoService: ModelAttributeOptionService,
    private router: Router,
  ) {
    this.getOptions();
  }

  openEditPage({id}) {
    this.router.navigate([`/service/edit/${id}`]);
  }

  changeConditionValue(key, value) {
    this.conditions[key].value = value;
  }

  getDue(row: ServiceModel) {
    if (row.exact_start_time) {
      return {
        days: 0,
        hours: 0,
        order: 0,
      };
    }
    let expectStartTime = moment(row.expect_start_time);
    let now = moment();
    let days = expectStartTime.diff(now, 'days');
    let hours = Math.abs(expectStartTime.diff(now, 'hours')) - Math.abs(24 * days);
    return {
      days: days,
      hours: hours,
      order: expectStartTime.diff(now, 'hours'),
    };
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.discipline.items = data.service.discipline;
        this.conditions.status.items = data.service.status;
        this.conditions.severity.items = data.service.severity.map((item) => {
          item.option_name = item.option_value;
          return item;
        });
        this.conditions.service_type.items = data.service.service_type;
      });
  }

  onFilter(keyword) {
    this.loadPage({keyword}).subscribe();
  }

  refresh(options = {resetPage: false}) {
    if (options['resetPage']) {
      this.paginator.reset();
    }
    this.loadPage().subscribe();
  }

  loadPage({keyword}: any = {keyword: ''}) {
    this.pageLoading = true;
    let request = new LaravelPageRequest(this.paginator.size, this.paginator.pageNumber, this.conditions, keyword);
    return this.index(request)
      .pipe(
        tap(
          (response: LaravelPageResponse) => {
            this.paginator.size = response.per_page;
            this.paginator.pageNumber = response.current_page;
            this.paginator.totalElements = response.total;
            this.items = response.data;
            this.items = this.items.map((service: ServiceModel) => {
              service['due'] = this.getDue(service);
              return service;
            });
          }
        ),
      )
      .finally(() => this.pageLoading = false);
  }

  index(params: LaravelPageRequest): Observable<LaravelPageResponse | any> {
    return this.http.get('services', {params: params.request()});
  }

  clickPage({offset}) {
    this.paginator.pageNumber = offset + 1;
    this.loadPage().subscribe();
  }
}
