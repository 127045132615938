import {FinanceService} from './../../shared/services/api/finance.service';

import {AuthGuard} from '@app/shared/services/system/auth/auth.guard';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FileUploader} from 'ng2-file-upload';
import {NotifyService} from '@app/shared/services/notify.service';
import {DocumentService} from '@app/shared/services/api/document.service';
import {UploaderService} from '@shared/services/uploader.service';

@Component({
  selector: 'app-upload-invoice',
  template: `
    <h1 mat-dialog-title translate>Upload Invoice</h1>
    <div mat-dialog-content>
      <p translate>You need to fill in some information to create approval</p>
      <div fxLayout="row" fxLayoutWrap="wrap">
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <mat-select [formControl]="type" placeholder="Type" required>
              <mat-option [value]="'generate'">
                {{"Generate"}}
              </mat-option>
              <mat-option [value]="'upload'">
                {{"Upload"}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-container *ngIf="type.value === 'upload'">
          <div fxFlex="100" class="mt-1">
            <button mat-raised-button color="accent" type="button" class="full-width">
              <input style="position: absolute; left: 0px; top: 0px; opacity: 0; -ms-filter: 'alpha(opacity=0)';height: 100%;"
                     type="file" value="upload" ng2FileSelect class="full-width" [uploader]="uploader"/>
              Add Document
            </button>
          </div>
          <div fxFlex="100" class="mt-1" *ngIf="uploader.queue.length > 0">
            <ng-container *ngFor="let item of uploader?.queue">
              <mat-chip (removed)="item.remove()">{{item?.file.name}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </ng-container>
          </div>
          <div fxFlex="100" class="mt-1">
            <button mat-raised-button (click)="uploadCertificateFile()" color="primary" class="full-width" [disabled]="!uploader.getNotUploadedItems().length">
              <mat-icon>cloud_upload</mat-icon>
              Upload
            </button>
          </div>
          <div fxFlex="100" class="mt-1" *ngIf="uploader.isUploading">
            <mat-progress-bar class="full-width" clolr="primary" mode="determinate" [value]="uploader.progress">
            </mat-progress-bar>
          </div>
        </ng-container>
        <ng-container *ngIf="type.value === 'generate'">
          <div fxFlex="100" class="mt-1">
            <button mat-raised-button (click)="viewPdf()" color="accent" class="full-width">
              <mat-icon>subject</mat-icon>
              View Online
            </button>
          </div>
          <div fxFlex="100" class="mt-1">
            <button mat-raised-button (click)="generateCertificateFile()" color="primary" class="full-width">
              <mat-icon>save</mat-icon>
              Save to server
            </button>
          </div>
        </ng-container>
      </div>
  `
})
export class UploadInvoice implements OnInit {
  public uploader: FileUploader;
  type = new FormControl('upload'); // generate or upload

  constructor(
    public dialogRef: MatDialogRef<UploadInvoice>,
    @Inject(MAT_DIALOG_DATA) public data: { invoiceId: string },
    private auth: AuthGuard,
    private toast: NotifyService,
    private financeService: FinanceService,
    private loader: AppLoaderService,
    private documentService: DocumentService,
    private uploaderService: UploaderService,
  ) {
  }

  ngOnInit(): void {
    this.uploader = this.uploaderService.get({url: this.financeService.uploadCertificateFileUrl(this.data.invoiceId)});
    // this.uploader.onBuildItemForm = (fileItem, form) => {
    //   this.uploader.options.additionalParameter = { ...this.itemForm.value, ...this.submitFormData };
    // };
    // upload pdf
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Upload success!');
        this.uploader.clearQueue();
        this.dialogRef.close(true);
      } else {
        this.toast.show('Upload failed!');
      }
    };
  }

  viewPdf() {
    this.documentService.viewPdf('invoice', this.data.invoiceId);
  }

  generateCertificateFile() {
    let loader = this.loader.open();
    this.financeService.generateCertificateFile(this.data.invoiceId)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Save pdf success.');
        this.dialogRef.close(true);
      });
  }

  uploadCertificateFile() {
    this.uploader.uploadAll();
  }
}
