import * as _ from 'lodash';

export function getQueryParam(prop) {
  let params = {};
  let search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
  let definitions = search.split('&');
  definitions.forEach(function (val, key) {
    let parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return (prop && prop in params) ? params[prop] : params;
}

export function parseDate(obj, path, def = '') {
  let date = _.get(obj, path, def);
  if (date) {
    return (new Date(date)).toISOString();
  } else {
    return '';
  }
}
