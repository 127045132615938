import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { CompanyService } from '@shared/services/api/company.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyOptions, ModelAttributeOptions } from '@shared/models/options';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class CompanyCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemForm: FormGroup;
  public companyOption: CompanyOptions;

  constructor(
    private _service: CompanyService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private router: Router,
    private maoService: ModelAttributeOptionService,
  ) { }

  ngOnInit() {
    this.getOptions();
    this.buildItemForm();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      industry: [''],
      name: ['', Validators.required],
      alias: [''],
      address1: [''],
      address2: [''],
      town: [''],
      country: [''],
      city: [''],
      postcode: [''],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      status: ['', Validators.required],
    });

  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.companyOption = data.company;
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service.store(this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.snack.open('Company Added!', 'OK', { duration: 4000 });
        SimpleReuseStrategy.deleteRouteSnapshot('///settings/company/create');
      });
  }

  gotoTable() {
    this.router.navigate(['/settings/company']);
  }
}
