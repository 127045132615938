import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { ChangeTimesheetTimeComponent } from '@shared/services/system/change-timesheet-time/change-timesheet-time.component';

@Injectable()
export class ChangeTimesheetTimeService {

  constructor(private dialog: MatDialog) {
  }

  public show(salaryTime): Observable<boolean> {
    return this.dialog.open(ChangeTimesheetTimeComponent, {
      width: '380px',
      disableClose: false,
      data: { salaryTime }
    }).afterClosed();
  }
}
