export class Conditions {
  [key: string]: {
    nullable: boolean;
    items?: object[];
    display_key?: string;
    select_key?: string;
    group_key?: string;
    item_key?: string;
    display_name?: string;
    value: string & {item: string; group: string} & any;
    useGroup?: boolean;
    groups?: {
      [group_name: string]: {
        display: string;
        items: {
          key: string;
          display: string;
        }[]
      }
    } | any;
  }
}
