import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-invoice-edit-popup',
  template: `
    <app-page-card-header title="CERTIFICATE {{data?.invoiceId}}" (onBack)="dialogRef.close()" [pdf]="{type: 'certificate', id: data.invoiceId}" [showStorePdfBtn]="false">
    </app-page-card-header>
    <div mat-dialog-content>
      <app-invoice-edit [id]="data.invoiceId" [inPopup]="true"></app-invoice-edit>
    </div>
  `
})
export class InvoiceEditPopup implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { invoiceId: number; view: boolean },
    public dialogRef: MatDialogRef<InvoiceEditPopup>,
  ) {
  }

  ngOnInit() {
  }
}
