import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '@shared/services/api/company.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions, CompanyOptions } from '@app/shared/models/options';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
  public loading$ = false;
  @Input() id;

  public itemFormGroup: FormGroup = new FormGroup({
    type: new FormControl({ value: [], disabled: true }, [Validators.required]),
    industry: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    status: new FormControl({ value: '', disabled: true }),
    data: new FormGroup({
      category: new FormControl({ value: [], disabled: true })
    })
  });
  public item;
  public options: CompanyOptions;

  constructor(
    private _service: CompanyService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.getOptions();
    this.show(this.id);
  }

  disabledType(type) {
    return type === 'agent';
  }


  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      ._finally(() => loader.close())
      .subscribe((data: any[]) => {
        this.item = data;
        this.itemFormGroup.patchValue(data);
      });
  }

  getOptions() {
    this.mao
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.company;
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service.update(this.id, this.itemFormGroup.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.router.navigate(['/settings/company']);
        this.snack.open('Company Updated!', 'OK', { duration: 4000 });
      });
  }

  refresh() {
    this.show(this.id);
  }
}
