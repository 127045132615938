import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {AssetModel} from '@shared/models/asset.model';
import {Observable} from 'rxjs';

@Injectable()
export class AssetService extends ApiBaseService {
  route_prefix = 'assets';

  public byBuildingId(buildingId): Observable<[AssetModel] | Object> {
    return this.http.get(`${this.route_prefix}/by-building-id/${buildingId}`);
  }
}
