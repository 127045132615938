import {LayoutService} from '@shared/services/system/layout.service';
import {QuotationModel} from '@shared/models/quotation.model';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {QuotationService} from '@shared/services/api/quotation.service';
import {QuotationItemService} from '@shared/services/api/quotation-item.service';
import {ServiceService} from '@shared/services/api/service.service';
import {QuotationOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyService} from '@shared/services/api/company.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {PopupService} from '@shared/services/popup.service';
import * as _ from 'lodash';
import {NotifyService} from '@app/shared/services/notify.service';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html'
})
export class QuotationEditComponent implements OnInit, OnDestroy {
  public loading$ = false;
  public itemForm: FormGroup = new FormGroup({
    type: new FormControl(''),
    description: new FormControl(''),
    status: new FormControl('', Validators.required),
  });
  public dataFormGroup: FormGroup = new FormGroup({
    service_id: new FormControl('', { updateOn: 'blur' }),
    out_of_hours_work_required: new FormControl('no'),
    we_require_another_discipline_contractor_for_these_works: new FormControl('no'),
    special_terms: new FormControl(''),
    client_reference: new FormControl(''),
    lead_in_time: new FormControl(''),
    work_duration: new FormControl(''),
    discipline: new FormControl({ value: '', disabled: true }),
    client_name: new FormControl({ value: '', disabled: true }),
    building_name: new FormControl({ value: '', disabled: true }),
    building_address: new FormControl({ value: '', disabled: true }),
  });

  public quotationOptions: QuotationOptions;
  public quotationItemDisplayColumns = [
    { display: 'Id', key: 'id', useColor: false },
    { display: 'Service Id', key: 'item_type_id', useColor: false },
    { display: 'Type', key: 'item_type', useColor: false },
    { display: 'Quantity', key: 'item_quantity', useColor: false },
    { display: 'Name', key: 'item_name', useColor: false },
    { display: 'Price', key: 'item_unit_price', useColor: false },
    { display: 'Markup (%)', key: 'item_unit_margin', useColor: false },
    { display: 'Total Price', key: 'item_total_price', useColor: false },
    { display: 'Status', key: 'item_status', useColor: true },
  ];
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public item: QuotationModel;
  public serviceIdIsValid = false;
  private onRefreshSub;

  constructor(
    private fb: FormBuilder,
    private _service: QuotationService,
    private serviceService: ServiceService,
    private quotationItemService: QuotationItemService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private router: Router,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public displayColumnsService: DisplayColumnsService,
    private popup: PopupService,
    public toast: NotifyService,
    private layout: LayoutService
  ) {
  }

  ngOnInit() {
    if (this.inPopup) {
      //
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.getOptions();
    this.buildItemForm();
    this.show();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());

  }

  buildItemForm() {
    this.dataFormGroup.get('service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService.checkValid(change, 'quotation')
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });
  }

  fillItemForm(item) {
    this.itemForm = this.fb.group(item);
    this.dataFormGroup.patchValue({
      service_id: _.get(item, 'data.service_id', ''),
      discipline: _.get(item, 'data.discipline', ''),
      out_of_hours_work_required: _.get(item, 'data.out_of_hours_work_required', ''),
      we_require_another_discipline_contractor_for_these_works: _.get(item, 'data.we_require_another_discipline_contractor_for_these_works', ''),
      special_terms: _.get(item, 'data.special_terms', ''),
      client_reference: _.get(item, 'data.client_reference', ''),
      lead_in_time: _.get(item, 'data.lead_in_time', ''),
      work_duration: _.get(item, 'data.work_duration', ''),
      client_name: _.get(item, 'data.client_name', ''),
      building_name: _.get(item, 'data.building_name', ''),
      building_address: _.get(item, 'data.building_address', ''),
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.quotationOptions = data.quotation;
      });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillItemForm(data);
      });
  }

  submit() {
    let data = { ...this.itemForm.value, id: this.id, data: this.dataFormGroup.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.refresh();
      })
      .subscribe(() => {
        this.snack.open('Quotation updated!', 'OK', { duration: 4000 });
      });
  }

  openEditServicePopUp(row) {
    this.popup.openServiceEditPage(row.id).afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  openQuotationItemCreatePopup() {
    this.popup.openQuotationItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.quotationItemService.destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.snack.open(`Quotation item ${row.id} deleted!`, 'OK', { duration: 4000 });
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup.openQuotationItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }
  gotoTable() {
    this.router.navigate(['/quotation']);
  }

  refresh() {
    this.show();
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }
}
