import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { PopupService } from '@shared/services/popup.service';
import { NoteService } from '@shared/services/api/note.service';
import { NoteModel } from '@shared/models/note.model';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-note-table',
  templateUrl: './note-table.component.html'
})
export class NoteTableComponent implements OnInit, OnDestroy {
  @Input() id;
  @Input() type;
  public items: NoteModel[] = [];
  private onRefreshSub;

  constructor(
    public displayColumnsService: DisplayColumnsService,
    private noteService: NoteService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private popup: PopupService,
    private layout: LayoutService,
    private loader: AppLoaderService,
  ) {
  }

  ngOnInit() {
    this.refresh();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.noteService.destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Note ${row.id} deleted!`);
            });
        }
      });
  }

  openEditPage(row) {
    this.popup.openNoteEditPage(row.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  refresh() {
    let loader = this.loader.open();
    this.noteService.indexByTypeAndTypeId(this.type, this.id)
      .finally(() => loader.close())
      .subscribe((response: NoteModel[]) => {
        this.items = response;
      });
  }

  severityStyle(value) {
    return this.noteService.severityStyle(value);
  }
}
