import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ContentChild, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs/Rx';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() multi = false;
  @Input() placeholder = '';
  @Input() items;
  @Input() field_classes;
  @Input() filter_column = 'name';
  @Input() display_column = 'name';
  @Input() value_column;
  @Input() disabled = false;
  @Input() form_ctrl: FormControl;
  @Output() onValueChange = new EventEmitter<number>();
  filter_ctrl: FormControl = new FormControl();
  filter_result: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ContentChild(TemplateRef) display: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.filter_ctrl.valueChanges
      .subscribe(() => {
        this.filter();
      });
    this.form_ctrl.valueChanges
      .subscribe((val) => {
        this.onValueChange.emit(val);
      });
  }

  ngOnChanges() {
    this.filter_result.next(this.items);
  }

  private filter() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.filter_ctrl.value;
    if (!search) {
      this.filter_result.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_result.next(this.items.filter((val) => {
      const field = '' + val[this.filter_column];
      return field.toLowerCase().indexOf(search) > -1;
    }));
  }

}
