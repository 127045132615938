import {Component, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ThemeService} from '@shared/services/system/theme.service';
import {egretAnimations} from '@shared/animations/egret-animations';
import {Router} from '@angular/router';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {LaravelPageResponse} from '@shared/models/laravel-page-response.model';
import {AppTableComponent} from '@shared/components/system/table/table.component';
import {Subscription} from 'rxjs';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Conditions} from '@shared/models/conditions.model';
import 'rxjs-compat/add/operator/finally';
import {LayoutService} from '@shared/services/system/layout.service';
import {Logger} from '@shared/services/system/logger.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {OnRefresh} from '@shared/components/base/on-refresh';
import {NotifyService} from '@app/shared/services/notify.service';

const Log = new Logger('BaseTableComponent');

@Component({
  selector: 'app-base-table',
  template: ``,
  animations: egretAnimations
})
export class BaseTableComponent implements OnInit, OnDestroy {

  @ViewChild(AppTableComponent) tableComponent: AppTableComponent;
  @Output() onClickEdit = new EventEmitter<number>();
  public onRefreshSub;
  public items;
  public conditions: Conditions;
  public loading$ = false;  // table data loading
  public inPopup = false;
  public keyword = '';
  // customers
  public itemName = '';
  public urlPrefix = '';
  public destroy = false;

  // providers
  public theme: ThemeService;
  public router: Router;
  public confirmService: AppConfirmService;
  public loader: AppLoaderService;
  public toast: NotifyService;
  public _service: ApiBaseService | any;
  public layout: LayoutService;
  public displayColumnsService: DisplayColumnsService;

  // subscribes
  indexSub: Subscription;

  constructor(
    protected injector: Injector
  ) {
    this.theme = this.injector.get(ThemeService);
    this.router = this.injector.get(Router);
    this.confirmService = this.injector.get(AppConfirmService);
    this.loader = this.injector.get(AppLoaderService);
    this.toast = this.injector.get(NotifyService);
    this.layout = this.injector.get(LayoutService);
    this.displayColumnsService = this.injector.get(DisplayColumnsService);
  }

  ngOnInit() {
    this.onRefresh(this.layout);
    this.refresh();
  }

  ngOnDestroy() {
    if (this.indexSub) {
      this.indexSub.unsubscribe();
    }
    this.unRefresh();
    this.destroy = true;
  }

  changeCondition() {
    this.refresh();
  }

  onRefresh(layout: LayoutService) {
    this.onRefreshSub = layout.onRefresh.subscribe(() => this.refresh());
  }

  unRefresh() {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  refresh() {
    this.loading$ = true;
    let size = this.inPopup ? 10 : 20;
    const request = new LaravelPageRequest(
      size,
      this.tableComponent.paginator.pageNumber + 1,
      this.conditions,
      this.keyword
    );
    if (this.indexSub) {
      this.indexSub.unsubscribe();
    }
    this.indexSub = this._service.index(request)
      .finally(() => this.loading$ = false)
      .takeWhile(() => !this.destroy)
      .subscribe((response: LaravelPageResponse) => {
        this.tableComponent.setPaginator(response.total, response.per_page, response.current_page - 1);
        this.items = response.data;
      });
  }

  openCreatePage() {
    Log.debug('openCreatePage url', `/${this.urlPrefix}/create`);
    this.router.navigate([`/${this.urlPrefix}/create`]);
  }

  openEditPage(row) {
    Log.debug('clickEdit url', `/${this.urlPrefix}/edit/${row.id}`);
    this.onClickEdit.emit();
    this.router.navigate([`/${this.urlPrefix}/edit/${row.id}`]);
  }

  deleteItem(row) {
    this.confirmService.confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service.destroy(row.id)
            .finally(() => loader.close())
            .subscribe((data: any) => {
              this.refresh();
              this.toast.show(`${this.itemName} ${row.id} deleted!`);
            });
        }
      });
  }

  changePage($event) {
    this.tableComponent.setPaginator($event.count, $event.pageSize, $event.offset);
    this.refresh();
  }


  filter($event) {
    this.keyword = $event;
    this.refresh();
  }
}
