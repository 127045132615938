import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { NoteService } from '@shared/services/api/note.service';
import { ModelAttributeOptions, NoteOptions } from '@app/shared/models/options';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';

@Component({
  selector: 'app-note-edit-popup',
  templateUrl: './note-edit.component.html'
})
export class NoteEditPopupComponent implements OnInit {
  public id;
  public inPopup = true;
  public itemForm: FormGroup;
  public item;
  public noteOptions: NoteOptions;

  constructor(
    private fb: FormBuilder,
    private _service: NoteService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<NoteEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.buildItemForm({});
    this.show(this.id);
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.noteOptions = data.note;
      });
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      note: [item.note || '', Validators.required],
      severity: [item.severity || '']
    });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.snack.open('Note updated!', 'OK', { duration: 4000 });
        this.dialogRef.close(true);
      });
  }

  gotoTable() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  severityStyle(value) {
    return this._service.severityStyle(value);
  }
}
