import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppLoaderService } from '../system/app-loader/app-loader.service';
import { NotifyService } from '../notify.service';
import {DocumentOptions, ModelAttributeOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';

@Injectable()
export class DocumentService extends ApiBaseService {

  route_prefix = 'documents';
  documentOptions: DocumentOptions;

  constructor(
    public http: HttpClient,
    public router: Router,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private maoService: ModelAttributeOptionService
  ) {
    super(http);
    this.getOptions();
  }
  uploadUrl() {
    return environment.apiURL + environment.adminPrefix + this.route_prefix;
  }

  certificates(service_id) {
    return this.http.get(`${this.route_prefix}/certificates`, { params: { service_id } });
  }

  verifyCertificate(document_id, data) {
    return this.http.post(`${this.route_prefix}/${document_id}/verify-certificate`, data);
  }

  indexByModelAndModelId(model, modelId) {
    return this.http.get(`${this.route_prefix}/${model}/${modelId}`);
  }

  storePdf(type, id) {
    return this.http.post(`documents/store-pdf`, { type, id });
  }

  viewPdf(type, id) {
    let loader = this.loader.open();
    this.http.get(`documents/get-pdf-url`, { params: { type, id }, responseType: 'text' })
      .finally(() => loader.close())
      .subscribe((url: any) => {
        window.open(encodeURI(url));
        this.toast.show('Open success');
      });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data['service']) {
          this.documentOptions = data.document;
        }
      });
  }

}
