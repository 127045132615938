import {Component, EventEmitter, OnInit, Output, OnDestroy} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {DashboardService} from '@shared/services/api/dashboard.service';
import {ThemeService} from '@shared/services/system/theme.service';
import {ServiceDashboardModel} from '@shared/models/service-dashboard.model';
import {LayoutService} from '@shared/services/system/layout.service';
import * as _ from 'lodash';
import {dashboardConfigs} from '@shared/config';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  animations: egretAnimations
})
export class ServiceDashboardComponent implements OnInit, OnDestroy {
  public loading = false;
  _ = _;
  public dashboard: ServiceDashboardModel = new ServiceDashboardModel;
  public displays = dashboardConfigs.service;
  destroy = false;
  refreshSubArr = [];
  @Output() onClickDashboard = new EventEmitter();

  constructor(
    private dashboardService: DashboardService,
    public theme: ThemeService,
    private layout: LayoutService,
    private globalSettingService: GlobalSettingService,
  ) {
  }

  ngOnInit() {
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
  }

  refresh() {
    if (this.refreshSubArr) {
      this.refreshSubArr.map((sub) => {
        sub.unsubscribe();
      });
    }
    this.loading = true;
    for (let group in this.displays) {
      if (group) {
        this.displays[group].items.map((item) => {
          let type = item.key;
          const refreshSub = this.dashboardService.getServiceDashboard(group, type)
            .takeWhile(() => !this.destroy)
            .finally(() => this.loading = false)
            .subscribe((data: number) => {
              this._.set(this.dashboard, [group, type], data);
            });
          this.refreshSubArr.push(refreshSub);
        });
      }
    }
  }

  openTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({group: dashboardGroup, item: dashboardType});
  }

  ngOnDestroy() {
    this.destroy = true;
  }

  getTabSum(displayKey) {
    let total = 0;
    for (const key in this.dashboard[displayKey]) {
      if (this.dashboard[displayKey].hasOwnProperty(key)) {
        const element = this.dashboard[displayKey][key];
        total += element;
      }
    }
    return total;
  }
}
