export class Paginate {
  // The number of elements in the page, default table size
  size = 20;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;

  reset() {
    this.size = 20;
    this.totalElements = 0;
    this.totalPages = 0;
    this.pageNumber = 0;
  }
}
