import { PurchaseOrderModel } from './purchase-order.model';
import { QuotationModel } from './quotation.model';
import { ServiceVisitModel } from '@shared/models/service-visit.model';
import { SaleModel } from './sale.model';
import { NoteModel } from '@shared/models/note.model';
import { BuildingModel } from '@shared/models/building.model';

export class ServiceModel {
  id: string;
  building_id: string;
  building_name: string;
  client_id: string;
  client_name: string;
  company_id: string;
  company_name: string;
  create_user_id: string;
  create_user_name: string;
  created_at: string;
  discipline: string;
  exact_end_time: string;
  exact_start_time: string;
  expect_end_time: string;
  expect_start_time: string;
  service_type: string;
  problem: string;
  severity: string;
  budget: string;
  status: string;
  subject: string;
  data: {
    assets: {
      id: number,
      compliance_sign_off: boolean,
      next_service_date: string,
      compliance_completion_date: string,
      category: string;
      building_name: string;
      name: string;
      last_service_time: string;
      next_service_time: string;
      compliance_certificate_document_id: string;
      compliant_status: string;
    }[],
    warranty: string;
    recall: string;
    compliance: string;
    out_of_hours_call_out: string;
    holding_pool: string;

    caller: string;
    related_services: { id: string; relation: string }[];
    cause: string;
    finance_type: string;
    response_time: string;
    relation: { service: { id: string; type: string }[] };
    client_reference: string;
    client: {
      client_reference: string;
    }
  };
  notes: NoteModel[];
  building: BuildingModel;
  can_complete: boolean;
  is_complete: boolean;
  is_pending: boolean;
  self_relation_status: string;
  supplier_company_ids: string[];

  // visit sale
  sales_group: {
    labours: SaleModel[];
    materials: SaleModel[];
    plants: SaleModel[];
    others: SaleModel[];
    labour_total: number;
    material_total: number;
    plant_total: number;
    other_total: number;
    total: number;
  };

  // service visit
  service_visit?: ServiceVisitModel[];

  // quotation
  quotations?: QuotationModel[];
  purchase_orders?: PurchaseOrderModel[];

  sales?: any[];
  company_service_relation?: {
    company_id: string;
    company_name: string;
    expect_start_time: string;
    expect_end_time: string;
    created_at: string;
    response_time: string;
    status: string;
    exact_start_time: string;
    exact_end_time: string;
    service_id: string;
  };
  is_waiting_create_sales?: boolean;
  is_waiting_create_application?: boolean;
  engineer_assigned_date?: string;
  service_visits?: ServiceVisitModel[];
  company_service_relations?: {
    company_id: string;
    company_name: string;
    expect_start_time: string;
    expect_end_time: string;
    created_at: string;
    response_time: string;
    status: string;
    exact_start_time: string;
    exact_end_time: string;
    service_id: string;
  }[];
  relation_services: ServiceModel[];
}
