import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';

@Injectable()
export class PurchaseOrderService extends ApiBaseService {
  route_prefix = 'purchase-orders';

  options() {
    return this.http.get(this.route_prefix + '/' + 'options');
  }

  pay(id) {
    return this.http.post(this.route_prefix + '/' + id + '/pay', {});
  }

  deliver(id) {
    return this.http.post(this.route_prefix + '/' + id + '/deliver', {});
  }

  suppliers() {
    return this.http.post('company/get-companies', { type: 'supplier' });
  }

  clients() {
    return this.http.get(this.route_prefix + '/clients');
  }

  serviceSuppliers(clientId = '') {
    return this.http.get(this.route_prefix + '/service-suppliers', { params: { client_id: clientId } });
  }
}
