import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceCustomizeService } from '../service-customize.service';

@Component({
  selector: 'app-service-customize-data-edit',
  templateUrl: './service-customize-data-edit.component.html',
  styleUrls: ['./service-customize-data-edit.component.scss']
})
export class ServiceCustomizeDataEditComponent implements OnInit {
  constructor(
    public customizeService: ServiceCustomizeService
  ) {
  }

  ngOnInit() {
  }

}
