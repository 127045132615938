import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptions, QuotationItemOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-quotation-item-edit-popup',
  templateUrl: './quotation-item-edit.component.html'
})
export class QuotationItemEditPopupComponent implements OnInit {
  public id;
  public view = false;
  public itemFormGroup: FormGroup = new FormGroup({
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_status: new FormControl('', Validators.required),
  });
  public lineTotal = 0;
  public item;
  public options: QuotationItemOptions
  @Output() onUpdated = new EventEmitter();

  constructor(
    private _service: QuotationItemService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    public dialogRef: MatDialogRef<QuotationItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; view: boolean },
    public maoService: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.view = this.data.view;
    this.buildItemForm();
    this.show(this.id);
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      let item_unit_margin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + item_unit_margin / 100);
    });
  }

  fillForm(item) {
    this.itemFormGroup.patchValue({ ...item });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemFormGroup.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show(this.id);
      })
      .subscribe(() => {
        this.onUpdated.emit();
        this.toast.show('Quotation item updated!');
        this.dialogRef.close(true);
      });
  }
}
