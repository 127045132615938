import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {LayoutService} from '../../../services/system/layout.service';
import {DocumentService} from '@app/shared/services/api/document.service';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-card-header',
  templateUrl: './page-card-header.component.html',
  styleUrls: ['./page-card-header.component.scss']
})
export class PageCardHeaderComponent implements OnInit {
  @Input() title = '';
  /** Cancel Button goto Path;**/
  @Input() gotoPath = '';
  @Input() loading = false; // not use, will be delete
  @Input() pdf: { id: number; type: string; };
  @Input() showViewPdfBtn = true;
  @Input() showStorePdfBtn = true;
  @Output() onBack = new EventEmitter();

  constructor(
    private router: Router,
    public layout: LayoutService,
    private documentService: DocumentService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private location: Location,
  ) {
  }

  ngOnInit() {
  }

  back() {
    if (this.gotoPath) {
      if (window.history.length > 1) {
        this.location.back();
      } else {
        this.router.navigate(['/']);
      }
    }
    this.onBack.emit();
  }

  viewPdf() {
    if (this.pdf) {
      this.documentService.viewPdf(this.pdf['type'], this.pdf['id']);
    }
  }

  storePdf() {
    if (this.pdf) {
      let loader = this.loader.open();
      this.documentService.storePdf(this.pdf['type'], this.pdf['id'])
        .finally(() => loader.close())
        .subscribe(() => {
          this.toast.show('Store success');
        });
    }
  }
}
