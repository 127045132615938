import { FinanceService } from './api/finance.service';
import { ThemeService } from '@shared/services/system/theme.service';
import { LayoutService } from '@shared/services/system/layout.service';
import { NavigationService } from '@shared/services/system/navigation.service';
import { RoutePartsService } from '@shared/services/system/route-parts.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MessageService } from '@shared/services/system/message.service';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { BuildingService } from '@shared/services/api/building.service';
import { CompanyService } from '@shared/services/api/company.service';
import { InvoiceService } from '@shared/services/api/invoice.service';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';
import { PublicService } from '@shared/services/api/public.service';
import { PurchaseOrderService } from '@shared/services/api/purchase-order.service';
import { ServiceService } from '@shared/services/api/service.service';
import { UserService } from '@shared/services/api/user.service';
import { DashboardService } from '@shared/services/api/dashboard.service';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { ApplicationService } from '@shared/services/api/application.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { PurchaseOrderItemService } from '@shared/services/api/purchase-order-item.service';
import { QuotationService } from '@shared/services/api/quotation.service';
import { DocumentService } from '@shared/services/api/document.service';
import { AssetService } from '@shared/services/api/asset.service';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import { PopupService } from '@shared/services/popup.service';
import { NoteService } from '@shared/services/api/note.service';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { AppSelectCompanyService } from '@shared/services/system/app-select-company/app-select-company.service';
import { TimesheetService } from '@shared/services/api/timesheet.service';
import { ChangeTimesheetTimeService } from '@shared/services/system/change-timesheet-time/change-timesheet-time.service';
import { SalaryTimeService } from '@shared/services/api/salary-time.service';
import { SettingService } from '@shared/services/api/setting.service';
import { RejectServiceService } from './system/reject-service/reject-service.service';
import { ApproveServiceService } from './system/approve-service/approve-service.service';
import { ProfileService } from './api/profile.service';
import { ServiceExtraService } from '../components/common/service-extra.service';
import { ServiceCustomizeService } from '../components/common/service-customize.service';
import { NotifyService } from './notify.service';
import { SettingsService } from './system/settings.service';
import { CacheService } from './cache.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

export const services = [
  ApiBaseService,
  ThemeService,
  NavigationService,
  RoutePartsService,
  AuthGuard,
  AppConfirmService,
  AppLoaderService,
  MessageService,
  LayoutService,
  PublicService,
  GlobalSettingService,
  PurchaseOrderService,
  PurchaseOrderItemService,
  DashboardService,
  ServiceService,
  InvoiceService,
  InvoiceItemService,
  QuotationService,
  QuotationItemService,
  UserService,
  NoteService,
  ServiceVisitService,
  CompanyService,
  BuildingService,
  ModelAttributeOptionService,
  AssetService,
  DocumentService,
  ApplicationService,
  DisplayColumnsService,
  PopupService,
  AppSelectCompanyService,
  TimesheetService,
  ChangeTimesheetTimeService,
  SalaryTimeService,
  SettingService,
  FinanceService,
  RejectServiceService,
  ApproveServiceService,
  ProfileService,
  ServiceExtraService,
  NotifyService,
  ServiceCustomizeService,
  SettingsService,
  CacheService,
  TimeConvertService
];
