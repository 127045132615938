import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-application-edit-popup',
  template: `
  <app-page-card-header title="APPLICATION {{data?.applicationId}}" (onBack)="dialogRef.close()" [pdf]="{type: 'application', id: data.applicationId}">
  </app-page-card-header>
    <app-application-edit [id]="data.applicationId" [inPopup]="true"></app-application-edit>`
})
export class ApplicationEditPopup implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { applicationId: number; },
    public dialogRef: MatDialogRef<ApplicationEditPopup>,
  ) {
  }

  ngOnInit() {
  }
}
