import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import { environment } from '@env/environment';
import { ServiceVisitOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import * as _ from 'lodash';
import { DisplayColumnsService } from '@app/shared/services/display-columns.service';
import { AuditModel } from '@app/shared/models/audit.model';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-service-visit-edit-popup',
  templateUrl: './service-visit-edit.component.html'
})
export class ServiceVisitEditPopupComponent implements OnInit {
  _ = _;
  public id;
  public itemForm: FormGroup;
  public inPopup = true;
  public item;
  public options: ServiceVisitOptions;
  public sales = [];
  public audit: AuditModel;

  constructor(
    private fb: FormBuilder,
    private _service: ServiceVisitService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<ServiceVisitEditPopupComponent>,
    private maoService: ModelAttributeOptionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public displayColumnsService: DisplayColumnsService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.getOptions();
    this.buildItemForm({});
    this.show(this.id);
    this.getSales();
    this.getAudit();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.options = data.service_visit;
      });
  }
  getAudit() {
    this._service.audit(this.id).subscribe((audit: AuditModel) => {
      this.audit = audit;
    });
  }
  buildItemForm(item) {
    this.itemForm = this.fb.group({
      id: [{ value: item.id, disabled: true }],
      user_id: [{ value: item.user_id || '', disabled: true }],
      user_name: [{ value: item.user_name || '', disabled: true }],
      check_in_time: [{ value: item.check_in_time ? (new Date(item.check_in_time)).toISOString() : '', disabled: true }],
      check_out_time: [{ value: item.check_out_time ? (new Date(item.check_out_time)).toISOString() : '', disabled: true }],
      service_status: [{ value: item.service_status || '', disabled: true }],
      note: [{ value: item.note || '', disabled: true }],
      observer: [{ value: item.observer || '', disabled: true }],
      rate_type: [{ value: item.rate_type || '', disabled: true }],
      log_type: [{ value: item.log_type || '', disabled: true }],
      created_at: [{ value: item.created_at || '', disabled: true }],
      signature: [{ value: item.signature || '', disabled: true }],
      check_in_latitude: [{ value: item.check_in_latitude || '', disabled: true }],
      check_in_longitude: [{ value: item.check_in_longitude || '', disabled: true }],
      check_out_latitude: [{ value: item.check_out_latitude || '', disabled: true }],
      check_out_longitude: [{ value: item.check_out_longitude || '', disabled: true }],
    });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  getSales() {
    this._service.sales(this.id).subscribe((sales: any) => {
      this.sales = sales;
    });
  }

  submit() {
    let itemFormValue = this.itemForm.value;
    itemFormValue['check_in_time'] = this.timeConvertService.convert(itemFormValue['check_in_time']);
    itemFormValue['check_out_time'] = this.timeConvertService.convert(itemFormValue['check_out_time']);
    let data = { ...itemFormValue };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show(this.id);
      })
      .subscribe(() => {
        this.snack.open('Visit updated!', 'OK', { duration: 4000 });
      });
  }

  refresh() {
    this.show(this.id);
  }

  gotoTable() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  getFullFileUrl(url) {
    if (url.substr(0, 1) === '/') {
      url = url.substr(1);
    }

    return environment.fileUrl + url;
  }
}
