import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {ModelAttributeOptions, UserCompanyOptions, UserOptions} from '@shared/models/options';
import {UserModel} from '@shared/models/user.model';
import {ReplaySubject} from 'rxjs';
import {LayoutService} from '@shared/services/system/layout.service';

@Injectable()
export class UserService extends ApiBaseService {
  route_prefix = 'users';
  public userOptions: UserOptions;
  public userCompanyOptions: UserCompanyOptions;
  public engineers: UserModel[] = [];
  public engineers$ = new ReplaySubject(1);

  constructor(
    public http: HttpClient,
    private mao: ModelAttributeOptionService,
    private layout: LayoutService,
  ) {
    super(http);
    this.mao.all().subscribe((data: ModelAttributeOptions) => {
      this.userOptions = data.user;
      this.userCompanyOptions = data.user_company;
    });
    this.getEngineers();
    this.layout.onRefresh.subscribe(() => this.getEngineers());
  }

  accountantsByServiceId(serviceId = '') {
    return this.http.get(this.route_prefix + '/accountants-by-service-id/' + serviceId);
  }

  engineersByCompanyId(companyId = '') {
    return this.http.get(this.route_prefix + '/engineers-by-company-id' + (companyId ? ('/' + companyId) : ''));
  }

  sendEmail(params) {
    return this.http.post('auth/password/send-email', params);
  }

  resetPassword(params) {
    return this.http.post('auth/password/reset', params);
  }

  private getEngineers() {
    this.http.get('users/engineers').subscribe((users: UserModel[]) => {
      this.engineers = users;
      this.engineers$.next(users);
    });
  }
}
