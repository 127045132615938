import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Conditions } from '@shared/models/conditions.model';
import { isArray } from 'lodash';
import { isObject } from 'lodash';
import { isNumber } from 'lodash';
import { isString } from 'lodash';

@Component({
  selector: 'app-table-condition',
  templateUrl: './condition.component.html'
})
export class AppTableConditionComponent implements OnInit {
  @Input() conditions: Conditions;
  @Output() changeConditionEvent = new EventEmitter<number>();

  objectKeys = Object.keys;
  isArray = isArray;
  isObject = isObject;
  isNumber = isNumber;
  isString = isString;

  constructor() { }

  ngOnInit() {
  }

  changeCondition() {
    this.changeConditionEvent.emit();
  }

  removeValue(conditionKey, value) {
    if (isArray(value)) {
      this.conditions[conditionKey].value.filter(condition => condition[conditionKey].value != value);
    } else {
      this.conditions[conditionKey].value = '';
    }
    this.changeCondition();
  }
}
