import {HtmlPipe} from './pipes/html.pipe';
import {UpliftComponent} from './components/business/uplift/uplift.component';
import {TableFilterComponent} from './components/system/table-filter/table-filter.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import {HeaderSideComponent} from './components/system/header-side/header-side.component';
import {SidebarSideComponent} from './components/system/sidebar-side/sidebar-side.component';
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import {HeaderTopComponent} from './components/system/header-top/header-top.component';
import {SidebarTopComponent} from './components/system/sidebar-top/sidebar-top.component';
// ONLY FOR DEMO (Removable without changing any layout configuration)
import {CustomizerComponent} from '@shared/components/system/customizer/customizer.component';
// ALL TIME REQUIRED
import {AdminLayoutComponent} from '@shared/components/system/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from '@shared/components/system/layouts/auth-layout/auth-layout.component';
import {NotificationsComponent} from '@shared/components/business/notifications/notifications.component';
import {SidenavComponent} from '@shared/components/system/sidenav/sidenav.component';
import {BreadcrumbComponent} from '@shared/components/system/breadcrumb/breadcrumb.component';
import {AppComfirmComponent} from '@shared/services/system/app-confirm/app-confirm.component';
import {AppLoaderComponent} from '@shared/services/system/app-loader/app-loader.component';
// DIRECTIVES
import {FontSizeDirective} from '@shared/directives/font-size.directive';
import {ScrollToDirective} from '@shared/directives/scroll-to.directive';
import {AppDropdownDirective} from '@shared/directives/dropdown.directive';
import {DropdownAnchorDirective} from '@shared/directives/dropdown-anchor.directive';
import {DropdownLinkDirective} from '@shared/directives/dropdown-link.directive';
import {EgretSideNavToggleDirective} from '@shared/directives/egret-side-nav-toggle.directive';
// PIPES
import {RelativeTimePipe} from '@shared/pipes/relative-time.pipe';
import {ExcerptPipe} from '@shared/pipes/excerpt.pipe';
import {DisplayUserTypePipe} from '@shared/pipes/display-user-type.pipe';
// SERVICES
import {GlobalSettingComponent} from '@shared/components/business/global-setting/global-setting.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {Md2DatepickerModule, NoConflictStyleCompatibilityMode} from 'md2';
import {FileUploadModule} from 'ng2-file-upload';
import {PageCardHeaderComponent} from '@shared/components/base/page-card-header/page-card-header.component';
import {ServiceCertificateVerifyPopupComponent} from '@shared/components/base/service-certificate-verify/service-certificate-verify-popup.component';
import {ServiceCertificateVerifyComponent} from '@shared/components/base/service-certificate-verify/service-certificate-verify.component';
import {BaseCreateComponent} from '@shared/components/base/base-create.component';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {AppTableConditionComponent} from '@shared/components/system/table/condition/condition.component';
import {AppTableComponent} from '@shared/components/system/table/table.component';
import {ServiceTablePopupComponent} from '@shared/components/business/service/service-table/service-table-popup.component';
import {ServiceTableComponent} from '@shared/components/business/service/service-table/service-table.component';
import {SelectSearchComponent} from '@shared/components/system/select-search/select-search.component';
import {SelectSearchClearDirective} from '@shared/components/system/select-search/select-search-clear.directive';
import {SimpleSelectSearchComponent} from '@shared/components/system/select-search/simple-select-search/simple-select-search.component';
import {ServiceEditComponent} from '@shared/components/business/service/service-edit/service-edit.component';
import {AppServiceCertificateComponent} from '@shared/components/base/app-service-certificate/app-service-certificate.component';
import {ServiceDashboardComponent} from '@shared/components/business/service/service-dashboard/service-dashboard.component';
import {QuotationItemCreatePopupComponent} from '@shared/components/business/quotation/quotation-item-create/quotation-item-create-popup.component';
import {QuotationEditComponent} from '@shared/components/business/quotation/quotation-edit/quotation-edit.component';
import {QuotationCreateComponent} from '@shared/components/business/quotation/quotation-create/quotation-create.component';
import {CompanyCreateComponent} from '@shared/components/business/company/company-create/company-create.component';
import {CompanyEditComponent} from '@shared/components/business/company/company-edit/company-edit.component';
import {ServiceEditPopupComponent} from '@shared/components/business/service/service-edit/service-edit-popup.component';
import {MatProgressButtonsModule} from 'mat-progress-buttons';
import {ServiceVisitEditPopupComponent} from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';
import {ServiceVisitTableComponent} from '@shared/components/business/service-visit/service-visit-table/service-visit-table.component';
import {NoteTableComponent} from '@shared/components/business/note/note-table/note-table.component';
import {NoteEditPopupComponent} from '@shared/components/business/note/note-edit/note-edit-popup.component';
import {DocumentCreatePopupComponent} from '@shared/components/business/document/document-create/document-create-popup.component';
import {NoteCreatePopupComponent} from '@shared/components/business/note/note-create/note-create-popup.component';
import {DocumentCreateComponent} from '@shared/components/business/document/document-create/document-create.component';
import {DocumentEditPopupComponent} from '@shared/components/business/document/document-edit/document-edit-popup.component';
import {DocumentTableComponent} from '@shared/components/business/document/document-table/document-table.component';
import {NoteCreateComponent} from '@shared/components/business/note/note-create/note-create.component';
import {QuotationItemEditPopupComponent} from '@shared/components/business/quotation/quotation-item-edit/quotation-item-edit-popup.component';
import {AppSelectCompanyComponent} from '@app/shared/services/system/app-select-company/app-select-company.component';
import {QuotationTablePopupComponent} from '@shared/components/business/quotation/quotation-table/quotation-table-popup.component';
import {QuotationTableComponent} from '@shared/components/business/quotation/quotation-table/quotation-table.component';
import {QuotationDashboardComponent} from '@app/views/quotation/quotation-dashboard/quotation-dashboard.component';
import {ChangeTimesheetTimeComponent} from '@shared/services/system/change-timesheet-time/change-timesheet-time.component';
import {ProgressComponent} from '@shared/components/system/progress/progress.component';
import {StrCheckboxComponent} from '@shared/components/system/str-checkbox/str-checkbox.component';
import {QuotationEditPopupComponent} from '@shared/components/business/quotation/quotation-edit/quotation-edit-popup.component';
import {QuotationCreatePopupComponent} from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import {SearchSelectComponent} from './components/system/search-select/search-select.component';
import {UploadInvoice} from '@app/views/finance/upload-invoice.component';
import {RejectServiceComponent} from './services/system/reject-service/reject-service.component';
import {ApproveServiceComponent} from './services/system/approve-service/approve-service.component';
import {ServiceCustomizeDataEditComponent} from './components/common/service-customize-data-edit/service-customize-data-edit.component';
import {ServiceExtraEditComponent} from './components/common/service-extra-edit/service-extra-edit.component';
import {BuildingDetailCardComponent} from './components/common/building-detail-card/building-detail-card.component';
import {BuildingNotesCardComponent} from './components/common/building-notes-card/building-notes-card.component';
import {ColorShipComponent} from './components/system/color-ship/color-ship.component';
import {UserCreateComponent} from './components/business/user/user-create/user-create.component';
import {UserEditComponent} from './components/business/user/user-edit/user-edit.component';
import {GalleryDialogComponent} from '@shared/components/business/gallery-dialog/gallery-dialog.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatDatepickerModule, Moment, MomentDateAdapter} from '@coachcare/datepicker';
import {UploaderService} from '@shared/services/uploader.service';
// import {Logger} from './services/logger.service';
/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormErrorPipe} from '@shared/pipes/form-error.pipe';
import {InvoiceEditPopup} from '@shared/components/business/invoice/invoice-edit/invoice-edit-popup.component';
import {ApplicationEditPopup} from '@shared/components/business/application/application-edit/application-edit-popup.component';
import {PurchaseOrderEditPopupComponent} from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit-popup.component';
import {InvoiceEditComponent} from '@shared/components/business/invoice/invoice-edit/invoice-edit.component';
import {ApplicationEditComponent} from '@shared/components/business/application/application-edit/application-edit.component';
import {PurchaseOrderEditComponent} from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit.component';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  CustomizerComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  FormErrorPipe,
  ExcerptPipe,
  HtmlPipe,
  DisplayUserTypePipe,
  GlobalSettingComponent,
  PageCardHeaderComponent,
  ServiceCertificateVerifyComponent,
  BaseCreateComponent,
  BaseTableComponent,
  AppTableConditionComponent,
  AppTableComponent,
  ServiceTableComponent,
  SelectSearchComponent,
  SelectSearchClearDirective,
  SimpleSelectSearchComponent,
  ServiceEditComponent,
  AppServiceCertificateComponent,
  ServiceDashboardComponent,
  QuotationEditComponent,
  QuotationCreateComponent,
  CompanyCreateComponent,
  CompanyEditComponent,
  ServiceVisitTableComponent,
  NoteTableComponent,
  NoteCreateComponent,
  DocumentTableComponent,
  DocumentCreateComponent,
  QuotationTableComponent,
  QuotationDashboardComponent,
  ProgressComponent,
  StrCheckboxComponent,
  TableFilterComponent,
  UpliftComponent,
  SearchSelectComponent,
  ServiceCustomizeDataEditComponent,
  ServiceExtraEditComponent,
  BuildingDetailCardComponent,
  BuildingNotesCardComponent,
  ColorShipComponent,
  UserCreateComponent,
  UserEditComponent,
  GalleryDialogComponent,
  InvoiceEditComponent,
  ApplicationEditComponent,
  PurchaseOrderEditComponent,
];

const popups = [
  AppComfirmComponent,
  AppLoaderComponent,
  QuotationItemCreatePopupComponent,
  ServiceEditPopupComponent,
  ServiceCertificateVerifyPopupComponent,
  ServiceTablePopupComponent,
  ServiceVisitEditPopupComponent,
  NoteEditPopupComponent,
  NoteCreatePopupComponent,
  DocumentCreatePopupComponent,
  DocumentEditPopupComponent,
  QuotationItemEditPopupComponent,
  AppSelectCompanyComponent,
  QuotationTablePopupComponent,
  ChangeTimesheetTimeComponent,
  QuotationEditPopupComponent,
  QuotationCreatePopupComponent,
  UploadInvoice,
  RejectServiceComponent,
  ApproveServiceComponent,
  GalleryDialogComponent,
  InvoiceEditPopup,
  ApplicationEditPopup,
  PurchaseOrderEditPopupComponent,
];

const imports = [
  CommonModule,
  FormsModule,
  RouterModule,
  FlexLayoutModule,
  TranslateModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatTabsModule,
  NgxDatatableModule,
  MatChipsModule,
  Md2DatepickerModule,
  FileUploadModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatProgressButtonsModule,
  NoConflictStyleCompatibilityMode,
  MatExpansionModule,
  NgxMatSelectSearchModule,
  GalleryModule,
  NgSelectModule,
];
export const MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    date: ['YYYY-MM-DD', 'YYYY/MM/DD', 'll'],
    datetime: ['YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm', 'll h:mma'],
    time: ['H:mm', 'HH:mm', 'h:mm a', 'hh:mm a']
  },
  display: {
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY HH:mm',
    time: 'HH:mm',
    dateA11yLabel: 'YY',
    monthDayLabel: 'MMM D',
    monthDayA11yLabel: 'MMMM D',
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    timeLabel: 'HH:mm'
  }
};

export class CustomAdapter extends MomentDateAdapter {
  format(date: Moment, displayFormat: string): string {
    date.toISOString = () => {
      return date.toLocaleString();
    };
    return super.format(date, displayFormat);
  }
}

@NgModule({
  imports: imports,
  entryComponents: popups,
  providers: [
    // LandingPageService
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: CustomAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    UploaderService,
  ],
  declarations: [
    ...classesToInclude,
    ...popups
  ],
  exports: [
    ...classesToInclude,
    ...imports
  ]
})
export class SharedModule {
}
