import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService {
  items: any[];

  constructor(private http: HttpClient) {
  }

  getServiceDashboard(group, type) {
    return this.http.get('dashboard/service', {params: { group, type }});
  }

  getQuotationDashboard() {
    return this.http.get('dashboard/quotation', {});
  }

  getHomeDashboard() {
    return this.http.get('dashboard/home', {});
  }
}
